import React from 'react'
import Helmet from 'react-helmet'
import Breadcrumbs from '../components/breadcrumbs'
import Layout from "../components/layout"
import DocumentiUtili from '../components/documenti-utili'
import NavMenu from '../components/nav-menu'
import Contatti from '../components/contatti'
import PhotoGallery from '../components/photogallery'

import { graphql } from "gatsby"
import Img from 'gatsby-image'


class RepartoTemplate extends React.Component {

  render() { 
    const data = this.props.data.reparto;
    const pagineFiglie = [{titolo: data.titolo, friendlyUrl: data.friendlyUrl}].concat(data.pagineFiglie);
    const contatti = {
      contatti: "Per maggiori informazioni puoi contattaci a questi riferimenti",
      indirizzo: "via dell’Università, 26900 Lodi",
      telefono: data.telefono || "02.50334111",
      email: data.email || "segreteriacentrovet@unimi.it"
    }
   
    const breadcrumb = [
      {"title":"Reparti","link":"/reparti"},
      {"title":  data.titolo}
    ];

    return (
      <Layout  location={this.props.location}>
        <div className="container">
          <Helmet title={data.titolo}/>
          <Breadcrumbs breadcrumb={breadcrumb}/>
          <h1 className="servizi__title">{data.titolo}</h1>
          <div className="row">
            <div className="col-md-8">
              <p>
                <Img fluid={data.immagine.fluid} />
              </p>
              <div className="bodytext" dangerouslySetInnerHTML={{ __html: data.descrizione.childMarkdownRemark.html }} >
                
              </div>
              {!!data.fotogallery &&
                <div className="magnific-popup">
                  <h2>Galleria fotografica</h2>
                  <PhotoGallery data={data.fotogallery}/>
                </div>
              }   
            </div>
            <div className="col-md-4">
              {!!data.pagineFiglie && 
                <NavMenu  active={data.friendlyUrl} data={pagineFiglie}/>
              }
              {!!data.documentiUtili &&
                <DocumentiUtili section="Documenti Utili" data={data.documentiUtili}/>
              }
              <Contatti data={contatti}/>
            </div>
          </div>
        </div>
      </Layout>
    )
  }

}

export default RepartoTemplate;

export const pageQuery = graphql`query RepartoDetailQuery($contentful_id: String!) {
  reparto: contentfulReparto(contentful_id: {eq: $contentful_id}) {
    immagine {
      fluid(maxWidth: 570){
        ...GatsbyContentfulFluid_withWebp
      }
    }
    titolo
    friendlyUrl
    telefono
    email
    descrizione {
      childMarkdownRemark {
        html
      }
    }
    documentiUtili {
      title
      file {
        url
      }
    }
    pagineFiglie {
      friendlyUrl
      titolo
    }
    fotogallery {
      ...PhotoGalleryFragment
    }
  }
}
`;
